import React, { ReactNode } from 'react';
import { ContentWrapper } from './styles';

interface AppContainerProps {
  children: ReactNode;
}

const ContentBox: React.FC<AppContainerProps> = ({ children }) => {
  return (
    <ContentWrapper>
      {children}
    </ContentWrapper>
  );
};

export default ContentBox;
