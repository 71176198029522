import styled from 'styled-components';

export const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
`;

export const ModalContent = styled.div`
    background: white;
    padding: 20px;
    width: 90%;
    max-width: 500px;
    border-radius: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
`;

export const FieldInfo = styled.div`
    display: flex;
    align-items: center;

    h2 {
        font-size: 20px;
        color: var(--black);
    }
`;

export const CloseButton = styled.button`
    background: none;
    border: none;
    font-size: 30px;
    cursor: pointer;
    color: var(--black);
`;

export const VideoContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const VideoPlayer = styled.video`
    width: 100%;
    max-width: 600px;
    border-radius: 10px;
    margin-bottom: 20px;
`;

export const DownloadButton = styled.a`
    background-color: #555;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    font-size: 16px;
    cursor: pointer;
    background-image: linear-gradient(90deg, #ff8c00, #ff0080);

    &:hover {
        background-color: #777;
    }
`;
