import { dayOrder } from "./constants";

export const sortHours = (hours: string[]) => {
    return hours.sort((a, b) => {
        const [aStart] = a.split(' - ')[0].split('h');
        const [bStart] = b.split(' - ')[0].split('h');
        return parseInt(aStart) - parseInt(bStart);
    });
};

export const sortDays = (days: string[]) => {
    return days.sort((a, b) => dayOrder.indexOf(a) - dayOrder.indexOf(b));
};

export const timestampToHM = (dateString: string) => {
    const date = new Date(dateString);

    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');

    return `${hours}:${minutes}`;
};
