import styled from 'styled-components';

export const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

export const PopupContainer = styled.div`
  background-color: white;
  width: 90%;
  max-width: 400px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
`;

export const CloseButton = styled.button`
  margin-top: 10px;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  background-color: #555;
  color: white;
  border: none;
  width: 100px;
  font-size: 15px;

  background-image:linear-gradient(90deg, #ff8c00, #ff0080);

  &:hover {
    background-color: #777;
  }
`;