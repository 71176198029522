import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { fetchVideosByCourt } from '../../service/apiService';
import { Field, Video } from '../../types/types';
import { sortHours, timestampToHM } from '../../utils/date';

import thumbnailDefault from './../../assets/campo.png';

import { VideoItem, FieldInfo, FiltersContainer, FilterButton, FiltersBox, BackButton, VideoListContainer } from './styles';
import BottomNavigation from '../../components/BottomNavigation';
import AppContainerComponent from '../../components/appContainer';
import ContentBox from '../../components/contentBox';
import { dayOrder } from '../../utils/constants';
import VideoModal from '../../components/videoModal/VideoModal';

const CACHE_EXPIRATION_TIME = 5 * 60 * 1000;

const FieldDetails: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { field } = location.state as { field: Field };

    const [videos, setVideos] = useState<Video[]>([]);
    const [loading, setLoading] = useState(true);

    const [selectedCourt, setSelectedCourt] = useState<string | null>(null);
    const [selectedDay, setSelectedDay] = useState<string | null>(null);
    const [selectedHour, setSelectedHour] = useState<string | null>(null);
    const [selectedVideo, setSelectedVideo] = useState<Video | null>(null);
    const [openModal, setOpenModal] = useState<boolean>(false);

    const [hours, setHours] = useState<string[]>([]);

    const saveFiltersToCache = (court: string | null, day: string | null, hour: string | null) => {
        const filters = { court, day, hour };
        localStorage.setItem(`filters_${field.id}`, JSON.stringify(filters));
    };

    const loadFiltersFromCache = () => {
        const storedFilters = localStorage.getItem(`filters_${field.id}`);
        return storedFilters ? JSON.parse(storedFilters) : null;
    };

    const loadVideosFromCache = (courtId: number, selectedDay: string) => {
        const cachedData = localStorage.getItem(`videos_${courtId}_${selectedDay}`);
        if (cachedData) {
            const { videos, timestamp } = JSON.parse(cachedData);
            if (Date.now() - timestamp < CACHE_EXPIRATION_TIME) {
                return videos as Video[];
            }
        }
        return null;
    };

    const saveVideosToCache = (videos: Video[], courtId: number, selectedDay: string) => {
        const cacheData = { videos, timestamp: Date.now() };
        localStorage.setItem(`videos_${courtId}_${selectedDay}`, JSON.stringify(cacheData));
    };

    useEffect(() => {

        const cachedFilters = loadFiltersFromCache();
        if (!cachedFilters?.court || !cachedFilters?.day || !cachedFilters?.hour) {

            const court = field.courts[0];
            const defaultDay = dayOrder[0];
            setSelectedCourt(court.court_name);
            setSelectedDay(defaultDay);

        } else {

            const court = field.courts.filter(court => court.court_name === cachedFilters.court)[0];
            const day = cachedFilters.day;

            setSelectedCourt(court.court_name);
            setSelectedDay(day);

        };

    }, [field]);

    useEffect(() => {
        const fetchVideos = async () => {
            try {
                if (selectedDay && selectedCourt) {

                    setLoading(true)
                    setHours([]);
                    const court = field.courts.filter(court => court.court_name === selectedCourt)[0];

                    let videoData;
                    videoData = loadVideosFromCache(court.court_id, selectedDay)

                    if (videoData?.length) {
                        setVideos(videoData);
                    } else {
                        videoData = await fetchVideosByCourt(court.court_id, selectedDay);
                        setVideos(videoData);
                        saveVideosToCache(videoData, court.court_id, selectedDay);
                    }

                    const availableTimes = videoData
                        .filter((video: Video) => video.day === selectedDay)
                        .map((video: Video) => video.hour);

                    const sortedAvailableTimes = sortHours(Array.from(new Set(availableTimes)));
                    setHours(sortedAvailableTimes);

                    let hour;
                    if (selectedHour) {

                        hour = sortedAvailableTimes.filter(hour => hour === selectedHour)[0]
                    } else {
                        hour = sortedAvailableTimes[0];
                    }

                    setSelectedHour(hour || sortedAvailableTimes[0]);
                    saveFiltersToCache(court.court_name, selectedDay, hour);
                    setLoading(false)
                }
            } catch (error) {
                console.error('Erro ao buscar vídeos:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchVideos();
    }, [selectedDay, selectedCourt]);

    const filteredVideos = videos.filter(video => {
        return (!selectedDay || video.day === selectedDay) &&
            (!selectedHour || video.hour === selectedHour);
    });

    const handleVideoClick = (video: Video) => {
        setSelectedVideo(video);
        setOpenModal(true);
    };

    const handleBackClick = () => {
        navigate(-1);
    };

    const handleCourtSelect = (court: string) => {
        setSelectedCourt(court);
        saveFiltersToCache(court, selectedDay, selectedHour);
    };

    const handleDaySelect = (day: string) => {
        setSelectedDay(day);
        saveFiltersToCache(selectedCourt, day, null);
    };

    const handleHourSelect = (hour: string) => {
        setSelectedHour(hour);
        saveFiltersToCache(selectedCourt, selectedDay, hour);
    };

    return (
        <AppContainerComponent>
            <ContentBox>

                <FieldInfo>
                    <img src={field.logoUrl} alt={`${field.name} logo`} style={{ width: 50, height: 50, marginRight: 10 }} />
                    <div>
                        <h2>{field.name}</h2>
                        <BackButton onClick={handleBackClick}>
                            &#8592;
                        </BackButton>
                    </div>
                </FieldInfo>

                <FiltersContainer>
                    <FiltersBox>
                        {field.courts.map(court => (
                            <FilterButton
                                key={court.court_id}
                                onClick={() => handleCourtSelect(court.court_name)}
                                active={selectedCourt === court.court_name}
                            >
                                {court.court_name}
                            </FilterButton>
                        ))}
                    </FiltersBox>

                    <FiltersBox>
                        {dayOrder.map(day => (
                            <FilterButton
                                key={day}
                                onClick={() => handleDaySelect(day)}
                                active={selectedDay === day}
                            >
                                {day}
                            </FilterButton>
                        ))}
                    </FiltersBox>

                    <FiltersBox>
                        {hours.map(hour => (
                            <FilterButton
                                key={hour}
                                onClick={() => handleHourSelect(hour)}
                                active={selectedHour === hour}
                            >
                                {hour}
                            </FilterButton>
                        ))}
                    </FiltersBox>
                </FiltersContainer>

                <VideoListContainer>
                    {loading ? (
                        <p style={{ margin: 'auto', color: "black" }}>Carregando vídeos...</p>
                    ) : (
                        filteredVideos.map((video) => (
                            <VideoItem key={video.id} onClick={() => handleVideoClick(video)}>
                                <img width={'100%'} src={video.thumbnailUrl ? video.thumbnailUrl : thumbnailDefault} alt="" />
                                <div>
                                    <text>{timestampToHM(video.createdAt)}</text>
                                </div>
                            </VideoItem>
                        ))
                    )}
                </VideoListContainer>

                <BottomNavigation />
                {/* 🔹 Modal de Vídeo */}
                {openModal && <VideoModal video={selectedVideo} field={field} court={field.courts.filter(c => c.court_name === selectedCourt)[0]} onClose={() => setOpenModal(false)} />}
            </ContentBox>
        </AppContainerComponent>
    );
};

export default FieldDetails;
