import styled from 'styled-components';

export const ContentWrapper = styled.div`
  max-width: 500px;
  margin: 0 auto 0 auto;
  color: var(--black);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Container = styled.div`
  padding: 20px;
  overflow: hidden;
  max-width: 500px;
`;

export const FieldInfo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  h2 {
    color: var(--black);
    font-size: 20px;
  }

  div {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
`;

export const FiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
  overflow-y: auto;
  height: 200px;
  border-radius: 10px;
  border: 1px solid var(--black);
  padding: 5px;

  @media (max-height: 700px) { 
    height: 170px; 
  }
`;

export const FiltersBox = styled.div`
  width: max-content;
  height: 50px;
`;

export const FilterButton = styled.button<{ active: boolean }>`
  background: transparent;
  color: var(--black);
  padding: 8px;
  margin: 5px;
  cursor: pointer;
  border-radius: 5px;
  border: 2px solid transparent;
  background-image: ${({ active }) =>
    active
      ? 'linear-gradient(90deg, #ff8c00, #ff0080)'
      : 'transparent'};
  background-origin: border-box;
  background-clip: border-box;
  box-shadow: ${({ active }) =>
    active
      ? '0px 1000px 0px var(--bg-color_white) inset'
      : 'none'};

`;

export const VideoListContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-y: auto;
  max-height: 500px;
  align-items: baseline;
  border-radius: 7px;
  border-right: 1px solid var(--black);
  height: 39vh;

  @media (max-height: 700px) { 
    height: 35vh; 
  }
`;

export const VideoItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;
  border-radius: 10px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;

  text {
    font-weight: 600;
  }
`;

export const BackButton = styled.button`
  background-color: transparent;
  color: var(--black);
  border: none;
  font-size: 30px;
  cursor: pointer;
  margin-left: 80px;
`;
