import React from 'react';
import { ModalOverlay, ModalContent, CloseButton, VideoContainer, VideoPlayer, DownloadButton, Header, FieldInfo } from './styles';

import { Video, Field, Court } from '../../types/types';
import SocialMediaBox from '../socialMediaBox';
import { downloadVideo } from '../../service/apiService';

interface VideoModalProps {
    video: Video | null;
    field: Field;
    court: Court | null;
    onClose: () => void;
}

const VideoModal: React.FC<VideoModalProps> = ({ video, field, court, onClose }) => {
    if (!video || !court) return null;

    const handleDownload = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();

        const a = document.createElement('a');
        a.href = video.downloadUrl;
        a.download = "";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        const date = new Date().toISOString();

        downloadVideo(video.id, court.court_id, field.id, date);
    };

    return (
        <ModalOverlay>
            <ModalContent>
                <Header>
                    <FieldInfo>
                        <img src={field.logoUrl} alt="Logo" style={{ width: 50, height: 50, marginRight: 10 }} />
                        <h2>{field.name}</h2>
                    </FieldInfo>
                    <CloseButton onClick={onClose}>&times;</CloseButton>
                </Header>
                <VideoContainer>
                    <VideoPlayer controls autoPlay>
                        <source src={video.downloadUrl} type="video/mp4" />
                        Seu navegador não suporta a reprodução de vídeo.
                    </VideoPlayer>
                    <DownloadButton href={video.downloadUrl} onClick={handleDownload}>
                        Download
                    </DownloadButton>
                    <SocialMediaBox
                        title='Quer ver seus videos em destaque?'
                        subtitle='nos envie através das nossas redes sociais!'
                        subtitleSize={14}
                    />
                </VideoContainer>
            </ModalContent>
        </ModalOverlay>
    );
};

export default VideoModal;
